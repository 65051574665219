<template>
  <v-card outlined>
    <v-card-text class="grey lighten-4">
      <v-sheet id="commentaire-sheet" min-height="100" max-height="200" class="overflow-y-auto grey lighten-4">
        <v-card style="margin-left: 8px; padding-left: 8px;" class="primary lighten-4 mb-4" v-for="(item, index) in commentaires" :key="index">
          <h4 v-if="item.created_by !== null">{{ item.created_by.fullname }} <span
              class="font-italic grey--text">{{ formatDate(item.date_creation) }}</span></h4>
          <p style="padding: 0; margin:0">{{ item.message }}</p>
          <v-row v-if="item.pieces_jointes != null && item.pieces_jointes.length > 0">
            <v-chip target="_blank" v-for="pieceJointe in item.pieces_jointes" :key="pieceJointe.id" text
                    :href="pieceJointe.file">
              {{ pieceJointe.path.split("/")[5] }}
            </v-chip>
          </v-row>
          <v-row class="mt-2">
            <v-dialog v-if="isResponsableOrAdmin" width="500">
              <template v-slot:activator="{on, attrs}">
                <v-chip v-on="on" v-bind="attrs" class="red white--text" style="margin: 4px;">
                  <v-icon color="white">mdi-delete</v-icon>
                  Supprimer le commentaire
                </v-chip>
              </template>
              <v-card>
                <v-card-title class="headline red lighten-1 white--text">
                  Supprimer le commentaire
                </v-card-title>
                <v-card-text class="body-1 mt-2">
                  Confirmer la suppression du commentaire ?
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="deleteCommentaire(item)" text color="red">Supprimer le commentaire</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-if="isResponsableOrAdmin" width="500">
              <template v-slot:activator="{on, attrs}">
                <v-chip v-on="on" @click="setEditText(item)" v-bind="attrs" class="primary white--text ma-1">
                  <v-icon color="white">mdi-pencil</v-icon>
                  Modifier le commentaire
                </v-chip>
              </template>
              <v-card>
                <v-card-title class="headline primary lighten-1 white--text">
                  Modifier le commentaire
                </v-card-title>
                <v-textarea class="pa-4" label="Commentaire" v-model="commentEdit"></v-textarea>
                <v-card-actions>
                  <v-btn @click="editCommentMessage(item)" text color="primary">Modifier le commentaire</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card>
      </v-sheet>
    </v-card-text>
    <v-form @submit="addCommentaire"
          onSubmit="return false;" ref="form">
      <v-row style="margin-left: 8px; margin-right: 8px; padding: 0" justify="space-between">

        <v-col style="padding: 0" cols="8" sm="10">
          <v-text-field class="mb-0" label="Message" v-model="message"></v-text-field>
          <span class="caption mt-0" v-if="file !== null">Pièce jointe : {{file.name}}</span>
        </v-col>
        <v-col cols="4" sm="2">
          <v-file-input ref="uploader" v-model="file" label="Fichier" class="d-none"></v-file-input>
          <v-row justify="space-around">
            <v-btn @click="file === null ? addFile() : removeFile()" rounded
                   :class="file === null ? ['primary'] : ['red', 'lighten-2']" class="white--text ml-2"
                   elevation="1" small icon fab>
              <v-icon v-if="file === null">mdi-attachment</v-icon>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{on, attrs}">
                  <v-icon v-on="on" v-bind="attrs">mdi-trash-can</v-icon>
                </template>
                <span>Retirer la pièce jointe</span>
              </v-tooltip>
            </v-btn>
            <v-btn @click="addCommentaire" rounded class="primary white--text ml-2" small icon fab>
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {AuthMixin} from "@/mixins/util/AuthMixin";

export default {
  name: 'Commentaires',
  mixins: [DateUtilMixin, AuthMixin],
  async mounted() {
    if (this.ticket) {
      await this.$store.dispatch("tickets/getTicketById", this.ticket.id)
      let selected_ticket = this.$store.getters["tickets/getSelectedTicket"];
      await this.$store.dispatch("commentaires/fetchCommentairesByTicket", selected_ticket);
    } else if(this.contract) {
      await this.$store.dispatch("contracts/getContractById", this.contract.id)
      let selected_contract = this.$store.getters["contracts/getSelectedContract"];
      await this.$store.dispatch("commentaires/fetchCommentairesByContract", selected_contract);
    }

    let sheet = this.$el.querySelector("#commentaire-sheet");
    sheet.scrollTop = sheet.scrollHeight;
  },
  data() {
    return {
      file: null,
      isSelecting: false,
      message: "",
      deleteDialog: false,
      commentEdit: "",
      editCommentDialog: false,
    }
  },
  props: {
    ticket: {
      type: Object,
      default: null,
    },
    contract: {
      type: Object,
      default: null,
    }
  },
  computed: {
    selected_ticket() {
      return this.$store.getters["tickets/getSelectedTicket"];
    },
    selected_contract() {
      return this.$store.getters["contracts/getSelectedContract"];
    },
    commentaires() {
      return this.$store.getters["commentaires/commentaires"];
    },
    logged_user() {
      return this.$store.getters["users/getLoggedUser"];
    },
    type() {
      return this.contract === null ? 'ticket' : 'contract';
    }
  },
  methods: {
    addFile() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true})
      console.log(this.$refs.uploader);
      this.$refs.uploader.$refs.input.click();
    },
    removeFile() {
      this.file = null;
    },
    addCommentaire() {
      if(this.$refs.form.validate()) {
        let commentaire = {
          "attachment": this.file,
          "message": this.message,
          "created_by": this.logged_user.id,
          "ticket": this.type === 'ticket' ? this.selected_ticket.id : null,
          "contract": this.type === 'contract' ? this.selected_contract.id : null,
        }
        this.$store.dispatch("commentaires/postCommentaire", {
          "ticket": this.type === 'ticket' ? this.selected_ticket : null,
          "contract": this.type === 'contract' ? this.selected_contract : null,
          commentaire
        });
        this.resetFields();
      }
    },
    async deleteCommentaire(commentaire) {
      await this.$store.dispatch("commentaires/deleteCommentaire", commentaire);
      await this.$store.dispatch("messages/announceSuccess", "Commentaire supprimé.");
      await this.fetchCommentaires();
    },
    async editCommentMessage(commentaire) {
      commentaire.message = this.commentEdit;
      await this.$store.dispatch("commentaires/patchCommentaire", commentaire);
      await this.$store.dispatch("messages/announceSuccess", "Commentaire modifié.");
      await this.fetchCommentaires();
      this.editCommentDialog = false;
    },
    async fetchCommentaires() {
      if(this.type === "ticket") {
        await this.$store.dispatch("commentaires/fetchCommentairesByTicket", this.selected_ticket);
      } else {
        await this.$store.dispatch("commentaires/fetchCommentairesByContract", this.selected_contract);
      }
    },
    setEditText(commentaire) {
      this.commentEdit = commentaire.message;
    },
    resetFields() {
      this.message = "";
      this.file = null;
    },
    preventDefault(event) {
      if(event) {
        console.log(event);
        event.preventDefault();
      }
    }
  },
}
</script>
