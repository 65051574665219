<template>
  <v-card elevation="0" flat style="max-height: 100vh" class="overflow-y-auto">
    <v-app-bar flat dark color="primary">
      <v-toolbar-title>S'assigner le ticket</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text>
      <v-form class="px-3">
        <v-autocomplete label="Métiers"
                  :items="metiers"
                  v-model="metier"
                  item-text="name"
                  item-value="id"></v-autocomplete>
        <v-row>
          <v-col cols="8">
            <v-menu max-width="100%" v-model="dateDebutMenu" :close-on-click="true" :close-on-content-click="false">
              <template v-slot:activator="{on, attrs}">
                <v-text-field :rules="[notNullNorEmpty]"  v-on="on" v-bind="attrs" label="Date du début"
                              :value="getFormattedDate(date_debut)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker @click="date_fin = date_debut" v-model="date_debut"></v-date-picker>
                <v-card-actions>
                  <v-btn text color="primary" @click="dateDebutMenu = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu max-width="100%" v-model="heureDebutMenu" :close-on-click="true" :close-on-content-click="false">
              <template v-slot:activator="{on, attrs}">
                <v-text-field :rules="[notNullNorEmpty]" v-on="on" v-bind="attrs" label="Heure du début"
                              :value="heure_date_debut"></v-text-field>
              </template>
              <v-card>
                <v-time-picker format="24hr" v-model="heure_date_debut"></v-time-picker>
                <v-card-actions>
                  <v-btn text color="primary" @click="heureDebutMenu = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-menu v-model="dateFinMenu" max-width="100%" :close-on-click="true" :close-on-content-click="false">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Date du fin"
                              :value="getFormattedDate(date_fin)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker v-model="date_fin"></v-date-picker>
                 <v-card-actions>
                  <v-btn text color="primary" @click="dateFinMenu = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu v-model="heureFinMenu" max-width="100%" :close-on-click="true" :close-on-content-click="false">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Heure de fin"
                              :value="heure_date_fin"></v-text-field>
              </template>
              <v-card>
              <v-time-picker format="24hr" v-model="heure_date_fin"></v-time-picker>
                <v-card-actions>
                  <v-btn text color="primary" @click="heureFinMenu = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="assignTicket">
        Assigner le ticket
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ValidationRulesMixin from "@/mixins/util/ValidationRulesMixin";

dayjs.extend(customParseFormat)

export default {
  name: 'InterventionAssignementForm',
  mixins: [ValidationRulesMixin],
  data() {
    return {
      "metier": null,
      "prestataire": null,
      "date_debut": null,
      "heure_date_debut": null,
      "date_fin": null,
      "heure_date_fin": null,
      "dateDebutMenu": false,
      "heureDebutMenu": false,
      "dateFinMenu": false,
      "heureFinMenu": false,
    }
  },
  mounted() {
    if (this.metiers.length === 0) {
      this.$store.dispatch("categories/fetchCategories");
    }
    if (this.users.length === 0) {
      this.$store.dispatch("users/fetchUsers");
    }
  },
  computed: {
    ...mapGetters("categories", [
      "metiers",
    ]),
    ...mapGetters("users", [
      "prestataires",
      "users",
      "collaborateurs",
    ]),
    logged_user() {
      return this.$store.getters["users/getLoggedUser"];
    }
  },
  methods: {
    getFormattedDatetime(date, time) {
      return date + " " + time;
    },
    getCleanDate(date, time) {
      return date !== null && time !== null ? dayjs(this.getFormattedDatetime(date, time), "YYYY-MM-DD hh:mm").toISOString() : null;
    },
    getFormattedDate(date) {
      return date != null ? dayjs(date).format("DD/MM/YYYY") : null;
    },
    async assignTicket() {
      let ticketId = this.$store.getters["tickets/getSelectedTicket"].id;
      let intervention = {
        "date_intervention": this.getCleanDate(this.date_debut, this.heure_date_debut),
        "date_fin_intervention": this.getCleanDate(this.date_fin, this.heure_date_fin),
        "validation": true,
        "cout_achat": null,
        "ticket": ticketId,
        "prestataire": this.logged_user.id,
        "categorie": this.metier
      }
      try {
        await this.$store.dispatch("interventions/postIntervention", intervention);
        await this.$store.dispatch("tickets/patchTicket", {"id": ticketId,"state": "ready"});
        await this.$store.dispatch("messages/announceSuccess", "Intervention correctement assigné.")
      } catch (e) {
        console.error(e);
      }
      this.$store.dispatch("drawer/closeDialog");
      this.resetFields();
    },
    resetFields() {
      this.metier = null;
      this.prestataire = null;
      this.date_debut = null;
      this.heure_date_debut = null;
      this.date_fin = null;
      this.heure_date_fin = null;
    }
  }
}
</script>
