<template>
  <v-card elevation="0" flat style="max-height: 100vh" class="overflow-y-auto">
    <v-app-bar flat dark color="primary">
      <v-toolbar-title>Proposer à un prestataire</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text>
      <v-form ref="ticketPrestataireForm" class="px-3">
        <v-select label="Métiers"
                  :items="metiers"
                  v-model="metier"
                  item-text="name"
                  item-value="id"></v-select>
        <v-select label="Prestataire"
                  :items="prestataires"
                  item-text="fullname"
                  v-model="prestataire"
                  :rules="[notNullNorEmpty]"
                  item-value="id"></v-select>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="assignTicket">
        Assigner le ticket
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ValidationRulesMixin from "@/mixins/util/ValidationRulesMixin";

dayjs.extend(customParseFormat)

export default {
  name: 'InterventionPropositionForm',
  mixins: [ValidationRulesMixin],
  data() {
    return {
      "metier": null,
      "prestataire": null,
    }
  },
  mounted() {
    if (this.metiers.length === 0) {
      this.$store.dispatch("categories/fetchCategories");
    }
    if (this.users.length === 0) {
      this.$store.dispatch("users/fetchUsers");
    }
  },
  computed: {
    ...mapGetters("categories", [
      "metiers",
    ]),
    ...mapGetters("users", [
      "prestataires",
      "users",
      "collaborateurs",
    ]),
  },
  methods: {
    async assignTicket() {
      if (this.$refs.ticketPrestataireForm.validate()) {
        let ticketId = this.$store.getters["tickets/getSelectedTicket"].id;
        let intervention = {
          "date_intervention": null,
          "date_fin_intervention": null,
          "validation": false,
          "cout_achat": null,
          "ticket": ticketId,
          "prestataire": this.prestataire,
          "categorie": this.metier
        }
        let store = this.$store;
        try {
          await this.$store.dispatch("interventions/postIntervention", intervention);
          await this.$store.dispatch("tickets/patchTicket", {"id": ticketId, "state": "waiting"});
          store.dispatch("messages/setAnnounce", {"type": "success", "message": "Intervention créée !"});
        } catch (e) {
          console.error(e);
        }
        this.$store.dispatch("drawer/closeDialog");
        this.resetFields();
      }
    },
    resetFields() {
      this.metier = null;
      this.prestataire = null;
    },

  }
}
</script>
